/* eslint-disable @next/next/no-img-element */
import { useRef } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper';

import dynamic from 'next/dynamic';
const SRLWrapper = dynamic(() => import("simple-react-lightbox").then((module) => module.SRLWrapper));

import { ImageWrapper, Main, Navigation } from './styled';

const options = {
  caption: {
    showCaption: false
  }
};

export default function WelcomeSlider({ customClassNames, slides }) {
  const swiperRef = useRef()
  return (
    <Main>
        <SRLWrapper options={options}>
          <Swiper
            ref={swiperRef}
            modules={[EffectFade]} effect="fade"
            loop={false}
            slidesPerView={1}
            speed={
              1000
            }
            onInit={(swiper) => {
              swiperRef.current = swiper;
            }}>
            {slides.map((slide, indx) => {
              const { src, thumb } = slide;
              return (
                <SwiperSlide key={indx}>
                  <ImageWrapper href={src} >
                    <img src={thumb} alt="Cлайд" />
                  </ImageWrapper>
                </SwiperSlide>
              )
            })
            }
          </Swiper>
        </SRLWrapper>

        <Navigation>
          <button arealabel="Листать слайдер влево" onClick={() => swiperRef.current.slidePrev()}><img src="/arrow-left.png" alt="Листать слайдер влево" /></button>
          <button arealabel="Листать слайдер вправо" onClick={() => swiperRef.current.slideNext()}><img src="/arrow-right.png" alt="Листать слайдер вправо" /></button>
        </Navigation>
    </Main >
  )
}