import styled from '@emotion/styled';

export const Main = styled.div`
  position: relative;
`

export const ImageWrapper = styled.a`
  position: relative;
  display: block;
  width: 100%;
  aspect-ratio: 16 / 5.67;
  border: 1px solid #000;
  img {
    height: 100%;
    object-fit: cover;
  }
`

export const Navigation = styled.div`
  position: absolute;
  top: 50%;
  left:0;
  right: 0;
  transform: translateY(-50%);

  display: flex;
  justify-content: space-between;

  z-index: 100;
  pointer-events: none;
  padding: 0 30px;

  button {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    pointer-events: all;
    transition: all .3s ease;
    &:hover {
      background-color: #d4caa2;
    }
  }
`
